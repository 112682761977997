<template>
  <div class="plk_dbcreator">
    <Menu></Menu>
    <div class="viewer">
      <h5>{{$t(creationMode ? 'designer.cms.db.creator' : 'designer.cms.db.update')}}</h5>
      <form @submit.prevent="submitAction">
        <!-- Name -->
        <div class="twelve columns">
          <label for="name">{{$t('designer.cms.db.connection.name')}}</label>
          <input required id="name" class="u-full-width" type="text" v-model="newConnection.name">
        </div>
        <!-- Folder -->
        <FolderList class="twelve columns" :initial="newConnection.folder" title="designer.cms.db.connection.folder" @onChange="setConnection"/>
        <!-- URL -->
        <div class="six">
          <label for="url">{{$t('designer.cms.db.connection.url')}}</label>
          <input id="url" class="u-full-width" type="text" v-model="newConnection.url">
        </div>
        <!-- Driver -->
        <div class="six columns">
          <label for="driver">{{$t('designer.cms.db.connection.driver')}}</label>
          <input id="driver" class="u-full-width" type="text" v-model="newConnection.driver">
        </div>
        <!-- User -->
        <div class="six">
          <label for="userJDBC">{{$t('designer.cms.db.connection.user')}}</label>
          <input id="userJDBC" class="u-full-width" type="text" v-model="newConnection.user">
        </div>
        <!-- Pass -->
        <div class="six columns">
          <label for="passJDBC">{{$t('designer.cms.db.connection.pass')}}</label>
          <input id="passJDBC" class="u-full-width" type="password" v-model="newConnection.pass">
        </div>
        <!-- Dialect -->
        <div class="twelve columns mb-4">
          <label>{{$t('designer.cms.db.connection.dialectname')}}</label>
          <v-select v-model="newConnection.dialect" class="u-full-width" :placeholder="$t('designer.cms.db.connection.dialect')" taggable push-tags />
        </div>
        <!-- Extra Data -->
        <div class="twelve columns">
          <label for="extradata">{{$t('designer.cms.db.connection.extradata')}}</label>
          <span class="warning" v-if="newConnection.haveExtraData">{{$t('designer.cms.db.connection.warningextradata')}}</span>
          <textarea class="u-full-width mb-0" id="extradata" v-model="newConnection.extra"></textarea>
        </div>
        <div class="twelve columns" v-if="isAdmin">
          <label for="uploadDriver">{{$t('designer.cms.db.connection.uploaddriver')}}</label>
          <Uploader id="uploadDriver" :simpleFile="true" acceptType="application/java-archive" @loadFile="loadedFile"></Uploader>
        </div>
        <button type="submit" class="w-100  mt-5">
          {{$t(creationMode ? 'designer.cms.db.connection.create' : 'designer.cms.db.connection.update')}}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import FolderList from '../components/FolderList'
import Menu from '../../components/menus/Menu'
import mixincms from '@/mixins/cms'
import { VIEWS } from '@/router/design'
import { Store, CMS } from '@/store'
import Uploader from '@/views/design/components/uploaders/Uploader'
import vSelect from 'vue-select'

export default {
  name: 'dblist',
  components: {
    Menu,
    Uploader,
    FolderList,
    'v-select': vSelect
  },
  mixins: [mixincms],
  data () {
    return {
      creationMode: undefined,
      folders: [],
      newConnection: {
        name: '',
        dialect: '',
        folder: this.$route.query.folder || 'ROOT',
        url: 'jdbc:mysql://ip:3306/database',
        driver: 'com.mysql.jdbc.Driver',
        user: '',
        pass: '',
        extra: undefined
      }
    }
  },
  computed: {
    isAdmin () {
      return Store.getters[CMS.GETTERS.GET_USER]().isAdmin
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadFolders()
      this.loadConnection()
    },
    async loadConnection () {
      try {
        let creationMode = true
        if (this.$route.query.folder && this.$route.query.connection) {
          creationMode = false
          const connection = await this.$servicesPLK.cms.getConnection(this.$route.query.folder, this.$route.query.connection)
          if (!this.haveRelation(connection.action, 'update')) {
            throw new Error()
          }
          Vue.set(this, 'newConnection', connection.resource)
        }
        Vue.set(this, 'creationMode', creationMode)
      } catch (e) {
        await this.$router.push({
          name: VIEWS.CMS.DATABASE_LIST.name
        })
      }
    },
    loadedFile ({ filename, content }) {
      this.$servicesPLK.cms.uploadDriver(content.file)
    },
    async loadFolders () {
      this.folders = (await this.getFolders()).filter(e => this.haveRelation(e.action, 'create'))
    },
    submitAction () {
      if (this.creationMode) {
        this.createConnection()
      } else {
        this.updateConnection()
      }
    },
    async createConnection () {
      await this.$servicesPLK.cms.createConnectionInFolder(this.newConnection.folder, this.newConnection)
      await this.$router.push({
        name: VIEWS.CMS.DATABASE_LIST.name,
        query: { folder: this.newConnection.folder }
      })
    },
    updateConnection () {
      this.$modal.show('dialog', {
        title: this.$t('designer.cms.db.dialog.updatedatabase.title'),
        text: this.$t('designer.cms.db.dialog.updatedatabase.text'),
        buttons: [
          {
            title: this.$t('designer.cms.db.dialog.updatedatabase.discard'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('designer.cms.db.dialog.updatedatabase.confirm'),
            handler: async () => {
              this.$modal.hide('dialog')
              if (this.$route.query.folder && this.$route.query.connection) {
                await this.$servicesPLK.cms.updateConnection(this.$route.query.folder, this.$route.query.connection, this.newConnection)
              }
              await this.$router.push({
                name: VIEWS.CMS.DATABASE_LIST.name
              })
            }
          }
        ]
      })
    },
    setConnection (folder) {
      this.newConnection.folder = folder
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_dbcreator {
    height: 100vh;
    .viewer {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
      .warning {
        color: coral;
      }
    }
  }
</style>
